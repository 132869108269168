@import "../theme/sizes";
@import "../theme/colors";

.top-bar {
  height: $top-bar-size;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: space-between;
  margin-top: 30px;
  margin-left: 64px;
  margin-right: 40px;

  .logo-area {
    .logo {
      width: 500px;
      height: auto;
    }
  }
}
