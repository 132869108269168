@import "../theme/colors";

.left-side-menu {
  border-top: 1px solid $secondary;
  border-right: 1px solid $secondary;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;

  flex-basis: 18vw;
  @media (max-width: 600px) {
    flex-basis: auto;
  }

  .MuiListItemIcon-root {
    @media (max-width: 600px) {
      min-width: inherit;
    }
  }

  .MuiListItemButton-root {
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  .MuiTypography-root {
    color: $secondary;
    text-decoration: none;
    a,
    a:hover {
      text-decoration: none;
    }
    /** Hide text if on phone **/
    @media (max-width: 600px) {
      display: none;
    }
  }

  .MuiList-root {
    .MuiList-root {
      .MuiListItem-root {
        @media (min-width: 600px) {
          padding-left: 20px;
        }
      }
    }
  }

  .MuiSvgIcon-root {
    color: $secondary;
    position: relative;
    top: -3px;
  }

  /**
   * Remove the underline from all the links
   */
  a:visited,
  a:link,
  a:active,
  a:hover {
    text-decoration: none;
  }

  .MuiListItem-root a {
    width: 100%;

    &:hover {
      background-color: #ddd;
    }

    &.active {
      background-color: #ddd svg {
        fill: $secondary;
        width: 1.1em;
        height: 1.1em;
      }
    }

    &:not(.active) {
      svg {
        fill: $secondary;
      }
    }

    &.active {
      span {
        font-weight: bold;
      }
    }

    .MuiListItemButton-root {
      @media (max-width: 600px) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
  .menu-pages-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    overflow-y: auto;

    &.unauthenticated {
      justify-content: center;
      align-items: center;
    }
  }
}
