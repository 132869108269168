
.local-translated-text-with-original {
  &:hover {

  }
}

.local-translated-text-without-original {

}



.local-translated-text-original-text-tooltip.MuiTooltip-tooltip {
  font-size: 14px;
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  padding: 10px;
  max-width: 30vw;
  white-space: pre-wrap;
}

