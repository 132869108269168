@import "../theme/colors";
@import "../theme/sizes";

.page-wrapper-with-menu {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
}

.page-wrapper-with-menu .below-top-bar {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;

  .left-side-menu {
    height: calc(100vh - $top-bar-size);
  }

  .main-content-area {
    background-color: #ffffff;
    padding-top: 10px;
    padding-left: calc(max(2vw, 8px));
    padding-right: calc(max(2vw, 8px));
    padding-bottom: 60px;

    &.no-padding {
      padding: 0;
    }

    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    overflow-y: auto;
    height: calc(100vh - $top-bar-size);
  }

  .page-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    padding-bottom: 60px;

    &.no-padding {
      padding: 0;
    }
  }
}
