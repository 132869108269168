.application-booting-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(90vh - 200px);
  text-align: center;

  & > .application-booting-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 30px;
    p {
      margin: 0;
      padding: 0;
    }
  }
}