/**
 * Note that these theme colors should stay in sync with the colors in theme.js. If modifying one
 * You should modify the other.
 */
$primary-light: #151a67;
$primary-main: #0e1250;
$primary-dark: #0a0e43;
$primary-contrast: #ffffff;

$secondary-light: #b37a51;
$secondary-main: #67310a;
$secondary-dark: #4b1400;
$secondary-contrast: #ffffff;

$border-color: #ececed;
$mid-black: #4f4b5c;
$error: #cf2a2a;

$page-background: rgb(248, 248, 248);

$primary: #e99741;
$bold-text-color: #1e2b49;
$secondary: #1e2b49;
$text-color: #494443;
$secondary-dark: #1b2742;
