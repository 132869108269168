
.account-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  align-self: center;
  margin-right: 20px;

  .avatar-text {
    color: white;
    position: relative;
    top: 1px;
    font-size: 14px;
  }

  text-decoration: none;
}


.account-menu-paper {
  a:visited, a:link, a:active, a:hover {
    text-decoration: none;
    text-decoration-color: white;
    color: rgb(0, 0, 0, 0.87);
  }
}